
function HomeLogoText() {
    // Used through the app to display flash logo image in desktop
    // mode which is the text version of flash logo

    // BUILD
    return (
        <div className={`animate-appear h-[24px] min-h-[24px] max-h-[24px] mb-4`}>
            <img className={"h-full"} src={'/images/dashboardLogoText.png'} alt="Logo" />
        </div>
    );
}

// EXPORT
export default HomeLogoText