import SystemDesktopTopBarLayout from "../layouts/SystemDesktopTopBarLayout";
import HomeLogoText from "../images/HomeLogoText";


function HomeTopBar() {
    // Used in GradeStepScreen to display top bar in desktop or tablet
    // mode made of logo and back to home button

    // BUILD
    return (
        <SystemDesktopTopBarLayout extraClass={"border-b-2 border-greyLight3"}>
            <HomeLogoText />
        </SystemDesktopTopBarLayout>
    );
}

// EXPORT
export default HomeTopBar