import HomeTopBar from "../components/bars/HomeTopBar";
import HomeDesktopContentLayout from "../components/layouts/HomeDesktopContentLayout";
import PageBarChartCard from "../components/cards/PageBarChartCard";

function HomeScreen() {
    // Used in index.js as the app entry point for default route

    // BUILD
    return (
        <div className={"bg-backgroundLight3 w-full h-screen flex flex-col"}>
            <HomeTopBar />
            <HomeDesktopContentLayout>
                <PageBarChartCard />
            </HomeDesktopContentLayout>
        </div>
    );

    // METHODS

}

// EXPORT
export default HomeScreen