
function SystemCustomRadioButton({label, checked, onPressed}) {
    // Used through the app to display radio button with label

    // BUILD
    return (
        <div className={`${checked ? "cursor-auto" : "cursor-pointer"} flex justify-start items-center space-x-12`}
             onClick={onPressed}>
            <div className={`w-[24px] min-w-[24px] h-[24px] min-h-[24px] rounded-[24px] flex justify-center items-center border-[2px] bg-transparent ${checked ? "border-primaryLight1" : "border-white"}`}>
                <div className={`animate-appear ${checked ? "block bg-primaryLight1 w-[16px] min-w-[16px] h-[16px] min-h-[16px] rounded-[16px]" : "hidden"}`}></div>
            </div>
            <span className={`font-medium text-[16px] ${checked ? 'text-greyLight11' : 'text-greyLight9'}`}>
                {label}
            </span>
        </div>
    );
}

// EXPORT
export default SystemCustomRadioButton