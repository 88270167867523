import {useEffect, useState} from "react";
import {thirtyDaysAgoDateTime, todayDateTime} from "../../helpers/DateTimeHelper";
import {get_landing_page_view_metrics} from "../../api/pageViewMetric";
import {EventName} from "../../enum/PageViewMetric";
import BarChartParameters from "../others/BarChartParameters";
import PageBarChart from "../charts/PageBarChart";

function PageBarChartCard() {
    // Used in HomeScreen to display Page bar chart

    // PROPERTIES
    // ---- Meta
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    // ---- Functional
    const [eventName, setEventName] = useState(EventName.LANDING)
    const [labels, setLabels] = useState([])
    const [barData, setBarData] = useState(null)
    const [totalVisits, setTotalVisits] = useState(null)

    // HOOKS
    // ---- At rendering : if local flash is in storage, set flash variables
    useEffect(() => {
        getPageViewMetric(eventName)
    }, [eventName]);

    // BUILD
    return (
        <div className={"bg-backgroundLight5 h-[364px] min-h-[364px] shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] flex flex-col justify-start items-start rounded-[16px] mt-12"}>
            <div className={'w-full flex justify-between items-center px-16 mt-12'}>
                <span className={"font-semibold text-[16px] text-greyLight9 "}>
                    {"Nombre de visiteurs (30 derniers jours)"}
                </span>
                <span className={"font-semibold text-[18px] text-white"}>
                    {totalVisits !== null ? "Total : " + totalVisits : "N/A"}
                </span>
            </div>

            <div className={"w-full bg-greyLight9 h-[1px] min-h-[1px] mt-12"}></div>
            <div className={"flex justify-start items-center mt-32 pr-24"}>
                <PageBarChart labels={labels}
                              barData={barData}
                              isLoading={isLoading}
                              hasError={hasError} />
                <BarChartParameters eventName={eventName}
                                    onEventNamePressed={onEventNamePressed} />
            </div>

        </div>
    );

    // METHODS
    // ---- Set eventName
    function onEventNamePressed(pressedEventName) {
        // Defensive measure
        if (!pressedEventName || (pressedEventName === eventName))
            return
        // Execute
        setEventName(pressedEventName)
        getPageViewMetric(pressedEventName)
    }

    // ---- Get page view metric
    function getPageViewMetric(eventName) {
        if (!eventName)
            return
        // Switch loader on and error off
        setIsLoading(true)
        setHasError(false)
        // Prepare
        let data = {
            "eventName": eventName.event,
            "startDate": thirtyDaysAgoDateTime(),
            "endDate": todayDateTime()
        }
        // Perform
        get_landing_page_view_metrics(data)
            .then((res) => {
                console.log('res', res)
                const days = res.map((event) => event?.eventDay.slice(5))
                const mobileData = res.map((event) => event.deviceDetail?.mobile)
                const tabletData = res.map((event) => event.deviceDetail?.tablet)
                const desktopData = res.map((event) => event.deviceDetail?.desktop)
                const deviceData = {
                    "mobile": mobileData,
                    "tablet": tabletData,
                    "desktop": desktopData
                }
                const mobileTotal = mobileData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                const tabletTotal = tabletData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                const desktopTotal = desktopData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                setLabels(days)
                setBarData(deviceData)
                setTotalVisits(mobileTotal + tabletTotal + desktopTotal)
            }).catch((err) => {
            console.error(err)
            setHasError(true)
        }).finally(() => {
            setIsLoading(false)
        })
    }

}

// EXPORT
export default PageBarChartCard