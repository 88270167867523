import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter} from "react-router-dom";
import {RouterProvider} from "react-router";
import HomeScreen from "./screens/HomeScreen";
import {routes} from "./config/routes";
import ErrorScreen from "./screens/ErrorScreen";

// Router
const router = createBrowserRouter([
    {
        path: routes.home,
        element: <HomeScreen/>,
        errorElement: <ErrorScreen />
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
