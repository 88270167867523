import {api} from "./routes";
import {generatePath} from "react-router";
import {makeRequest, Methods} from "./request";

// List of methods triggering PageViewMetric related api calls

// METHODS
// ---- Get landing visit metrics
export function get_landing_page_view_metrics(data) {
    const url = api.domain + generatePath(api.routes.getFilteredPageViewMetric)
    return makeRequest(url, Methods.POST, data)
}