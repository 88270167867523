import {isNullOrUndefined} from "../../services/Validator";

function LandingDesktopContentLayout({children}) {
    // Used in LandingScreen to frame content in desktop
    // mode

    // BUILD
    if(isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-y-auto w-full h-full hidden md:flex md:flex-col items-center justify-start py-24"}>
            <div className={"w-[400px] md:w-[712px] lg:w-[944px] flex flex-col items-center justify-start"}>
                {children}
            </div>
        </div>
    );
}

// EXPORT
export default LandingDesktopContentLayout